import { AuthGuard, BUYING_2_PATH_APP, BUYING_ROOT } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const BuyConfirmedRoutes = {
  guard: AuthGuard,
  layout: PageLayout,
  path: `${BUYING_ROOT}/buy-confirmed`,
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP.buyConfirmed,
      component: lazy(() => import('../views/BuyConfirmedView')),
    },
  ],
};

export default BuyConfirmedRoutes;
