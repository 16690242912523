import { AuthGuard, BUYING_2_PATH_APP, BUYING_ROOT } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const OverviewRoutes = {
  guard: AuthGuard,
  layout: PageLayout,
  path: `${BUYING_ROOT}/new-buy`,
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP.newBuy,
      component: lazy(() => import('../views/NewBuyView')),
    },
  ],
};

export default OverviewRoutes;
