import { Batch } from '@autone/openapi-buying';

const extractBatchId = (url: string): Batch['id'] => {
  const regex = /\/batch\/([a-zA-Z0-9-]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return '';
  }
};

export const getBatchIdFromUrl = () => {
  const url = window.location.href;
  const batchId = extractBatchId(url);
  return {
    batchId,
  };
};

export const replaceBatchIdInUrl = (url: string, batchId: string) =>
  url.replace(':batchId', batchId);
