import { AuthGuard, BUYING_2_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { PageLayoutWithStepper } from '../layout';

const BatchRoutes = {
  guard: AuthGuard,
  layout: PageLayoutWithStepper,
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP.scope,
      component: lazy(() => import('../views/ScopeView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP.products,
      component: lazy(() => import('../views/ProductsView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP.assortment,
      component: lazy(() => import('../views/AssortmentView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP.otb,
      component: lazy(() => import('../views/BudgetView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP.review,
      component: lazy(() => import('../views/ReviewView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP['ideal-buy'],
      component: lazy(() => import('../views/IdealBuyView')),
    },
    {
      exact: true,
      path: BUYING_2_PATH_APP.summary,
      component: lazy(() => import('../views/SummaryView')),
    },
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={BUYING_2_PATH_APP.overview} />,
    },
    // if path is not recognised then redirect back to the homepage
    {
      component: () => <Redirect to={BUYING_2_PATH_APP.overview} />,
    },
  ],
};

export default BatchRoutes;
