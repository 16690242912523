import { BUYING_2_PATH_APP, useLocalStorage } from '@autone/ui';
import { useEffect } from 'react';

import { useGetModuleUserLastActiveQuery } from '../redux/services';

const useFirstTimeUserCheck = () => {
  const { data: modules, isLoading } = useGetModuleUserLastActiveQuery();
  const { localStorageState, setLocalStorage } = useLocalStorage({
    key: 'buying-first-time-user',
    defaultValue: { userHasAccessedBuying: false },
  });

  const userHasAccessedBuying = localStorageState.userHasAccessedBuying;

  useEffect(() => {
    // if loading then do nothing as we do not know if a user has accessed buying
    if (isLoading || !modules) {
      return;
    }

    const userBuyingRecord = modules.find(
      ({ module_id: moduleId }) => moduleId === 'BUY',
    );

    const isBuyingInLocalStorage = userHasAccessedBuying;
    const hasUserGotLastActive = userBuyingRecord
      ? userBuyingRecord.user_last_active
      : false;

    // a user has accessed buying if user last active is not null or we have a value in local storage
    const hasUserAccessedBuying =
      isBuyingInLocalStorage || hasUserGotLastActive;

    // if a user has not accessed buying before we redirect them to the welcome page
    if (!hasUserAccessedBuying) {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3979'
          : `https://${process.env.REACT_APP_DOMAIN}`;

      window.location.replace(`${baseUrl}${BUYING_2_PATH_APP.welcome}`);
    }

    // if buying is not in local storage than add it to local storage
    if (!isBuyingInLocalStorage) {
      const updatedLocalStorage = {
        userHasAccessedBuying: true,
      };
      setLocalStorage(updatedLocalStorage);
    }
  }, [isLoading, modules, userHasAccessedBuying]);
};

export default useFirstTimeUserCheck;
