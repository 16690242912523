import {
  CreateProductPotentialJobResponse,
  GetProductPotentialJobResponse,
} from '@autone/openapi-buying';
import { encodeUrl } from '@autone/ui';

import { buyingApi } from '../apis';

const productPotentialApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    createProductPotentialJob: builder.query<
      CreateProductPotentialJobResponse,
      {
        batchId: string;
      }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/product-potential/job`,
          variables: { batchId },
        }),
        method: 'POST',
      }),
    }),
    getStatusOfProductPotentialJob: builder.query<
      GetProductPotentialJobResponse,
      {
        batchId: string;
        jobId: string;
      }
    >({
      query: ({ batchId, jobId }) =>
        encodeUrl({
          url: `batch/{batchId}/product-potential/job/{jobId}`,
          variables: { batchId, jobId },
        }),
    }),
  }),
});

export const {
  useGetStatusOfProductPotentialJobQuery,
  useLazyCreateProductPotentialJobQuery,
} = productPotentialApis;
