export { STEPS } from './steps';

export const APP_NAME = 'buying';

export {
  INVALID_OTB_UPLOAD_RESPONSE_DETAIL,
  INVALID_OTB_UPLOAD_MESSAGE,
} from './otbUpload';
export { MAX_CONTAINER_CARD_HEIGHT_PX } from './cardHeights';
export const PRODUCTS_PER_PAGE = 10;
export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const TOOLTIP_ENTER_DELAY = 1000;
export const INITIAL_PRICE_TYPE = 'rrp';
export const PRICE_TYPE_DICT = {
  rrp: { otbLabel: 'Retail price', headerLabel: 'Retail' },
  ws: { otbLabel: 'Wholesale', headerLabel: 'Wholesale' },
};
export const WS = 'ws';
export const SKU_CAP_ERROR_CODE = 'NO_SKU_CAPACITIES';
