import { AuthGuard, BUYING_2_PATH_APP } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const QuantificationLoadingRoutes = {
  guard: AuthGuard,
  layout: PageLayout,
  path: BUYING_2_PATH_APP['product-catalogue-loading'],
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP['product-catalogue-loading'],
      component: lazy(() => import('../views/ProductCatalogueLoadingView')),
    },
  ],
};

export default QuantificationLoadingRoutes;
