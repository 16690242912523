import {
  AuthGuard,
  BUYING_2_PATH_APP,
  BUYING_ROOT,
  ErrorView,
} from '@autone/ui';

import { ERROR_CODES } from '../constants/errorCodes';
import { PageLayout } from '../layout';

const ErrorRoutes = {
  guard: AuthGuard,
  layout: PageLayout,
  path: `${BUYING_ROOT}/error/batch/:batchId/reasonCode/:reasonCode`,
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP.error,
      component: () => <ErrorView module={'buying'} errorCodes={ERROR_CODES} />,
    },
  ],
};

export default ErrorRoutes;
