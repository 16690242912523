import { amplifyCookieStorageConfig } from '@autone/ui';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
// central AWS config file
import config from './config/aws-exports';
// redux store
import { store } from './redux/store';

// set the mui x license key for the date picker
LicenseInfo.setLicenseKey(
  'd1991666a5f02351309dfb9177f3fd73Tz03Njk2MyxFPTE3MjkzNDQ5MDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

// configure amplify
Amplify.configure({
  ...config,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage(amplifyCookieStorageConfig),
);

// configure sentry
Sentry.init({
  dsn: 'https://6c20fc13c88f4a00a2d1f1ff4fb75ace@o1115656.ingest.sentry.io/6743590',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment:
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : process.env.REACT_APP_BUILD_STAGE,
});

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.REACT_APP_START_MOCK_WORKER === 'true') {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start();
    })
    .then(() => {
      root.render(
        <Provider store={store}>
          <App />
        </Provider>,
      );
    });
} else {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
