//@ts-ignore
import EUR from '../../svgs/eur_flag.svg';
//@ts-ignore
import GBP from '../../svgs/gb_flag.svg';
//@ts-ignore
import MAD from '../../svgs/morocco_flag.svg';
//@ts-ignore
import AED from '../../svgs/uae_flag.svg';
//@ts-ignore
import USD from '../../svgs/us_flag.svg';

export const getCurrencyIcon = (currency: string | null) => {
  switch (currency) {
    case 'GBP':
      return GBP;
    case 'EUR':
      return EUR;
    case 'USD':
      return USD;
    case 'MAD':
      return MAD;
    case 'AED':
      return AED;
    default:
      return '';
  }
};
