import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { FC } from 'react';

import { useGetCurrencyOptionsQuery } from '../../../redux/services';
import { getCurrencyIcon } from '../../../utils';
import { useGetBuyCurrency } from '../../../utils/hooks';
import { CHANGE_CURRENCY, trackMixpanelAction } from '../../../utils/mixpanel';

const CurrencyDropdown: FC = () => {
  const {
    data: currencyOptions,
    isLoading,
    isFetching,
  } = useGetCurrencyOptionsQuery();
  const { buyingCurrencyIso, handleChangeBuyCurrency } = useGetBuyCurrency();

  const handleChange = (event: SelectChangeEvent) => {
    trackMixpanelAction(CHANGE_CURRENCY, {
      currency: event.target.value,
    });
    handleChangeBuyCurrency(event.target.value as string);
  };

  return (
    <>
      <FormControl>
        <Select
          labelId="stepper-currency-dropdown"
          id="stepper-currency-dropdown"
          value={buyingCurrencyIso}
          onChange={handleChange}
          renderValue={(renderedValue) => {
            if (isLoading || isFetching || !currencyOptions) {
              return (
                <Stack sx={{ width: '60px' }} alignItems="center">
                  <CircularProgress size={18} />
                </Stack>
              );
            }

            return (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <img src={getCurrencyIcon(renderedValue)} alt="country icon" />
                {renderedValue}
              </Box>
            );
          }}
        >
          {currencyOptions?.map((currencyIso) => (
            <MenuItem
              key={currencyIso}
              value={currencyIso}
              sx={{ display: 'flex', gap: 1 }}
            >
              <img src={getCurrencyIcon(currencyIso)} alt="Your SVG" />
              {currencyIso}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CurrencyDropdown;
