import { BUYING_2_PATH_APP } from '@autone/ui';

export type StepKeys = [
  'scope',
  'product-catalogue-loading',
  'products',
  'assortment',
  'otb',
  'review',
  'ideal-buy',
  'quantification-loading',
  'summary',
];

export const STEPS = {
  scope: {
    title: 'Scope',
    url: BUYING_2_PATH_APP.scope,
    renderInStepper: true,
  },
  'product-catalogue-loading': {
    title: 'Product catalogue loading',
    url: BUYING_2_PATH_APP['product-catalogue-loading'],
    renderInStepper: false,
  },
  products: {
    title: 'Products',
    url: BUYING_2_PATH_APP.products,
    renderInStepper: true,
  },
  assortment: {
    title: 'Assortment',
    url: BUYING_2_PATH_APP.assortment,
    renderInStepper: true,
  },
  otb: {
    title: 'OTB',
    url: BUYING_2_PATH_APP.otb,
    renderInStepper: true,
  },
  review: {
    title: 'Review',
    url: BUYING_2_PATH_APP.review,
    renderInStepper: true,
  },
  'ideal-buy': {
    title: 'Ideal buy',
    url: BUYING_2_PATH_APP['ideal-buy'],
    renderInStepper: true,
  },
  'quantification-loading': {
    title: 'Ideal Buy Loading',
    url: BUYING_2_PATH_APP['quantification-loading'],
    renderInStepper: false,
  },
  summary: {
    title: 'Summary',
    url: BUYING_2_PATH_APP.summary,
    renderInStepper: true,
  },
};
