import { useDebounceEffect } from '@autone/ui';

import { useUrlParams } from '../../hooks';
import { store } from '../../redux/store';

const useGetBuyCurrency = () => {
  const userStore = store.getState().user;
  const { getUrlParam, updateUrlParam } = useUrlParams();
  const urlCurrencyIso: string | null = getUrlParam('currencyIso');

  const {
    config: { default_currency },
  } = userStore;

  // adding a delay to this effect so the appending of currency for the url doesn't interfere with the redirect of not found routes
  useDebounceEffect(
    () => {
      if (!urlCurrencyIso) {
        updateUrlParam(
          {
            parameterKey: 'currencyIso',
            parameterValue: default_currency.toUpperCase(),
          },
          true,
        );
      }
    },
    [],
    100,
  );

  const handleChangeBuyCurrency = (currencyIso: string): void => {
    updateUrlParam(
      {
        parameterKey: 'currencyIso',
        parameterValue: currencyIso,
      },
      true,
    );
  };

  return {
    buyingCurrencyIso: urlCurrencyIso || default_currency,
    handleChangeBuyCurrency,
  };
};

export default useGetBuyCurrency;
