import {
  ExportOTBRequest,
  ExportOTBResponse,
  ImportOTBResponse,
} from '@autone/openapi-buying';
import { encodeUrl } from '@autone/ui';

import { buyingApi } from '../apis/buying';

const otbApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    postImportOtb: builder.mutation<
      ImportOTBResponse,
      {
        aggregation: ExportOTBRequest['aggregation'];
      }
    >({
      query: ({ aggregation }) => ({
        url: `otb/import`,
        method: 'POST',
        body: { aggregation },
      }),
    }),
    postUploadOtbFile: builder.mutation<
      ImportOTBResponse,
      {
        url: string;
        file: File;
      }
    >({
      query: ({ url, file }) => ({
        url,
        method: 'PUT',
        body: file,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        formData: true,
      }),
      invalidatesTags: ['AssortmentMismatches'],
    }),
    postExportOtb: builder.mutation<
      ExportOTBResponse,
      {
        aggregation: ExportOTBRequest['aggregation'];
      }
    >({
      query: ({ aggregation }) => ({
        url: `otb/export`,
        method: 'POST',
        body: { aggregation },
      }),
    }),
    getPollExportJob: builder.query<
      ExportOTBResponse,
      {
        jobId: string;
      }
    >({
      query: ({ jobId }) => ({
        url: encodeUrl({ url: `otb/export/{jobId}`, variables: { jobId } }),
        method: 'GET',
      }),
    }),
    postExportCurrentOtb: builder.mutation<
      { url: string },
      {
        batchId: string;
      }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/otb/export`,
          variables: { batchId },
        }),
        method: 'POST',
      }),
    }),
  }),
});

export const {
  usePostImportOtbMutation,
  usePostUploadOtbFileMutation,
  usePostExportOtbMutation,
  useGetPollExportJobQuery,
  usePostExportCurrentOtbMutation,
} = otbApis;
