import { Batch, SubmitBatchResponse } from '@autone/openapi-buying';
import { encodeUrl } from '@autone/ui';

import { buyingApi } from '../apis';

const submitApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    submitBatch: builder.mutation<
      SubmitBatchResponse,
      { batchId: Batch['id'] }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/submission`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
  }),
});

export const { useSubmitBatchMutation } = submitApis;
