export const RESTORE_BUY = 'restore buy';
export const VIEW_SUMMARY = 'view summary';
export const DELETE_BUY = 'delete buy';
export const EDIT_BUY = 'edit buy';
export const CHANGE_NAME = 'change buy name';
export const CHANGE_OTB_CURRENCY = 'change otb currency';
export const CHANGE_OTB_VALUE = 'change otb value';
export const SELECT_CUSTOM_BREAKDOWN = 'select otb custom breakdown';
export const SELECT_CUSTOM_BREAKDOWN_TYPE = 'select otb custom breakdown type';
export const DOWNLOAD_TEMPLATE = 'download template';
export const UPLOAD_TEMPLATE = 'upload template';
export const CHANGE_WEEK_OF_SALE = 'change week of sale';
export const CHANGE_SELL_THROUGH_TARGET = 'change sell through target';
export const CHANGE_SKU_CAPACITY_TARGET = 'change sku capacity target';
export const CREATE_BUY = 'create buy';
export const CHANGE_COMPARABLE_SALES = 'change comparable sales';
export const SELECT_COMPARABLE_SEASON = 'select comparable season';
export const SELECT_DESELECT_COMPARABLE_EVENT =
  'select/deselect comparable event';
export const SELECT_ALL_COMPARABLE_EVENTS = 'select all comparable events';
export const DESELECT_ALL_COMPARABLE_EVENTS = 'deselect all comparable events';
export const TOGGLE_COMPARABLE_SALES = 'toggle comparable sales';
export const CHANGE_OTB_COMPARABLE_SALES = 'change otb comparable sales';
export const SELECT_OTB_COMPARABLE_SEASON = 'select otb comparable season';
export const SELECT_DESELECT_OTB_COMPARABLE_EVENT =
  'select/deselect otb comparable event';
export const SELECT_ALL_OTB_COMPARABLE_EVENTS =
  'select all otb comparable events';
export const DESELECT_ALL_OTB_COMPARABLE_EVENTS =
  'deselect all otb comparable events';
export const SELECT_SCOPE_OPTION = 'select scope option';
export const DESELECT_SCOPE_OPTION = 'deselect scope option';
export const SCOPE_ITEM_SELECT_ALL = 'select all items for a scope card';
export const SCOPE_ITEM_CLEAR_ALL = 'clear all items for a scope card';
export const BUY_CONFIRMED_VIEW_MY_BUYS = 'redirect to buying overview page';
export const REVIEW_CARD_CLICK =
  'click review card to review previous selections';
export const UPDATE_ORDER_QUANTITY = 'update order quantity';
export const ADD_ALL_TOP_RECOMMENDATIONS = 'add all top recommendations';
export const SELECT_PRODUCT_CARD = 'select product card';
export const DESELECT_PRODUCT_CARD = 'deselect product card';
export const FAVOURITE_PRODUCT = 'favourite product';
export const UNFAVOURITE_PRODUCT = 'unfavourite product';
export const REMOVE_SELECTED_PRODUCT =
  'remove product from the selected products tab';
export const SET_GEOGRAPHIC_AGGREGATION = 'set geographic aggregation filter';
export const ADD_ALL_PRODUCT_ASSORTMENT = 'add all products assortment page';
export const REMOVE_ALL_PRODUCT_ASSORTMENT =
  'remove all products assortment page';
export const UPDATE_ASSORTMENT = 'update assortment';
export const CREATE_QUANTIFICATION = 'create quantification';
export const EXPORT_TO_EXCEL = 'export to excel';
export const SELECT_BREAKDOWN_IDEAL_BUY =
  'select breakdown options on the ideal buy page';
export const DESELECT_BREAKDOWN_IDEAL_BUY =
  'deselect breakdown options on the ideal buy page';
export const SWITCH_TAB = 'switch tab';
export const SORT = 'sort';
export const CONFIRM_STEP_CHOICES = 'confirm step choices';
export const SUBMIT_BUY = 'submit buy';
export const CHECK_MISMATCHES = 'check mismatches';
export const CHANGE_CURRENCY = 'change currency';
export const SHARE_URL_BUTTON_CLICKED = 'share url button clicked';
export const SELECT_FILTER_OPTION = 'select filter option';
export const DESELECT_FILTER_OPTION = 'deselect filter option';
export const CHANGE_PRICE_RANGE_FILTER = 'change price range filter';
export const CHANGE_OTB_PRICE_TYPE = 'change otb price type';
export const UPDATE_TOTAL_LOCATION_QUANTITY = 'update total quantity';
export const SET_DEPARTMENT_AGGREGATION = 'set department aggregation filter';
export const DOWNLOAD_PRODUCT_TAGS_FILE = 'download product tags file';
export const UPLOAD_PRODUCT_TAGS_FILE = 'upload product tags file';
export const PRODUCT_TAGS_FILE_ACTION_FAILED =
  'product tags file action failed';
export const PRODUCT_TAGS_FILE_ACTION_SUCCESS =
  'product tags file action success';
export const EDIT_PRODUCT_QUANTITY = 'edit quantification product quantity';
export const RENAME_BATCH = 'rename batch';
export const ADD_STORE = 'add store';
