import {
  CustomerModule,
  CustomerModules,
  ListCurrenciesResponse,
  ProductAttribute,
} from '@autone/openapi-core';
import { encodeUrl } from '@autone/ui';

import { coreApi } from './apis';

type LastActiveResponse = {
  module_id: string;
  last_active: string;
};

type LastActivePayload = {
  module_id: string;
};

const coreApis = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyOptions: builder.query<string[], void>({
      query: () => `/currencies`,
      transformResponse: (response: ListCurrenciesResponse) =>
        response.currencies,
    }),
    getModuleUserLastActive: builder.query<CustomerModule[], void>({
      query: () => '/modules',
      transformResponse: (response: CustomerModules) =>
        // filter the modules to get the BUY module and return the user_last_active property
        response.modules,
    }),
    setLastActive: builder.mutation<LastActiveResponse, LastActivePayload>({
      query: ({ module_id: moduleId }) => ({
        url: encodeUrl({
          url: `/modules/{moduleId}/last-active`,
          variables: { moduleId },
        }),
        method: 'PUT',
      }),
    }),
    getCoreSeasons: builder.query<ProductAttribute[], void>({
      query: () => `/products/seasons`,
      transformResponse: (response: { seasons: ProductAttribute[] }) =>
        response.seasons,
    }),
    getCoreEvents: builder.query<
      ProductAttribute[],
      { seasonId: string | null }
    >({
      query: ({ seasonId }: { seasonId: string | null }) => ({
        url: encodeUrl({
          url: `/products/events?season_id={seasonId}`,
          variables: {
            seasonId: `${seasonId}`,
          },
        }),
      }),
      transformResponse: (response: { events: ProductAttribute[] }) =>
        response.events,
    }),
  }),
});

export const {
  useGetCurrencyOptionsQuery,
  useGetModuleUserLastActiveQuery,
  useSetLastActiveMutation,
  useGetCoreSeasonsQuery,
  useGetCoreEventsQuery,
} = coreApis;
