import { AuthGuard, BUYING_2_PATH_APP, BUYING_ROOT } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const QuantificationLoadingRoutes = {
  guard: AuthGuard,
  layout: PageLayout,
  path: `${BUYING_ROOT}/batch/:batchId/quantification/:jobId`,
  routes: [
    {
      exact: true,
      path: BUYING_2_PATH_APP['quantification-loading'],
      component: lazy(() => import('../views/QuantificationLoadingView')),
    },
  ],
};

export default QuantificationLoadingRoutes;
