import { Batch } from '@autone/openapi-buying';

import { useGetBatchQuery } from '../redux/services';

const useGetBatchInfo = ({ batchId }: { batchId: Batch['id'] }) => {
  const {
    data: batchInfo,
    isLoading,
    isFetching,
  } = useGetBatchQuery({ batchId }, { skip: !batchId });

  return {
    batchInfo,
    isLoading,
    isFetching,
  };
};

export default useGetBatchInfo;
