// Need to use the React-specific entry point to allow generating React hooks
import { customBaseQuery, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

const TAG_TYPES = [
  'Batches',
  'Batch',
  'Scope',
  'Products',
  'ProductFilters',
  'ProductMismatches',
  'TopProducts',
  'Assortment',
  'AssortmentFilters',
  'AssortmentMismatches',
  'Review',
  'IdealBuyKpis',
  'IdealBuyKpisWithFiltered',
  'IdealBuy',
  'IdealBuyAggregated',
  'IdealBuyFilters',
  'ImportOtb',
  'CatalogueDepartmentSkuCap',
];

// Define a service using the base buying URL and expected endpoints
export const buyingApi = createApi({
  reducerPath: 'buyingApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled('buying-api-v2');
    const uri = isV2Enabled ? 'v2/buying' : 'buying2';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: () => ({}),
});
