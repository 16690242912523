import { useEffect } from 'react';

import { useSetLastActiveMutation } from '../redux/services';

const useSetLastActive = () => {
  const [setLastActive] = useSetLastActiveMutation();
  /* as this mutation does not send any body data to manipulate the server, it could possibly be a query instead of a mutation.
Something to look in to, but left as a mutation for now */
  useEffect(() => {
    const handleSetLastActive = async () => {
      setLastActive({ module_id: 'BUY' });
    };
    handleSetLastActive();
  }, []);
};

export default useSetLastActive;
